// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

const resources = {
  stores: "logo",
  shows: "image",
  personals: "background",
  surveys: "image"
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
  if ((type === "CREATE" || type === "UPDATE") && resources[resource]) {
    const field = resources[resource];
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.picture is not an array, but single object
    if (params.data[field] && typeof params.data[field] === "object") {
      // only freshly dropped pictures are instance of File
      const newImage = params.data[field];
      return convertFileToBase64(newImage)
        .then(image64 => {
          return {
            src: image64,
            title: `${newImage.title}`
          };
        })
        .then(transformedNewLogo => {
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              [field]: transformedNewLogo
            }
          });
        });
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
