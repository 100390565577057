import React from "react";
import {
  Datagrid,
  Create,
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  BooleanField,
  ImageField,
  ReferenceManyField,
  TextInput,
  LongTextInput,
  NumberInput,
  BooleanInput,
  ImageInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  DeleteButton,
} from "react-admin";
import { PasswordField } from "../../components/PasswordField";
import { AddLocalUserButton, UpdateLocalUserButton } from "../../components/AddLocalUserButton";

const StoreTitle = ({ record }) => {
  return (
    <div className="storeTitle">
      <span>{record && record.id ? `${record.name}` : "Create Store"}</span>
      <img src={record.logo} width={48} alt={record.name} />
    </div>
  );
};

function formatLogo(value) {
  if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it 
    return { url: value };
  } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

export const StoreForm = props => {
  const MainForm = props.id ? Edit : Create;
  return (
    <MainForm {...props} title={<StoreTitle />}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" label="Name" />
          <TextInput source="uname" label="Unique name" />
          <NumberInput source="tel" label="Phone" />
          <LongTextInput source="address" label="Address" />
          <BooleanInput source="special.active" label="Active" defaultValue={false} />
          <BooleanInput source="special.has_category" label="Has category" defaultValue={false}/>
        </FormTab>
        <FormTab label="logo">
          <ImageInput format={ formatLogo } source="logo" label="Logo" accept="image/*">
            <ImageField source="url" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label="Category & Zone">
          <ReferenceArrayInput label="Select categories" reference="scategories" source="scategories">
              <CheckboxGroupInput optionValue="id" optionText="name" allowEmpty />
          </ReferenceArrayInput>
          <ReferenceArrayInput label="Select zones" reference="zones" source="zones">
              <CheckboxGroupInput optionValue="id" optionText="name" allowEmpty />
          </ReferenceArrayInput>
        </FormTab>
        {
          props.id && 
          <FormTab label="users">
            <ReferenceManyField label="Users" reference="localusers" target="store_id" source="id">
              <Datagrid>
                <TextField source="name" />
                <TextField source="email" />
                <BooleanField source="active" />
                <BooleanField source="root" />
                <PasswordField source="password" />
                <UpdateLocalUserButton />
                <DeleteButton />
              </Datagrid>
            </ReferenceManyField>
            <AddLocalUserButton />
          </FormTab>
        }
      </TabbedForm>
    </MainForm>
  );
};