import React from "react";
import {
  Filter,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  BooleanField,
  ReferenceField,
  TextInput,
  DisabledInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";
import { parse } from "query-string";

import ZonePreview from "../components/ZonePreview";
import { PasswordField } from "../components/PasswordField";

const LUFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const LUList = props => (
  <List filters={<LUFilter />} {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="store_id" reference="stores">
        <ZonePreview source="logo" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="email" />
      <PasswordField source="password" />
      <TextField source="tel" />
      <TextField source="tel2" />
      <BooleanField source="active" />
      <BooleanField source="root" />
    </Datagrid>
  </List>
);

export const LUEdit = props => {
  const { store_id: storeIdString } = parse(props.location.search);
  const storeId = storeIdString ? parseInt(storeIdString, 10) : "";
  const redirect = storeId ? `/stores/${storeId}/3` : false;

  const Title = () => {
    return <span>Update local user</span>;
  };
  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm redirect={redirect}>
          <DisabledInput source="id" />
          <ReferenceInput source="store_id" reference="stores" perPage={1000} disabled={true}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" />
          <TextInput source="email" />
          <TextInput source="password" />
          <BooleanInput source="active" defaultValue={true} />
          <BooleanInput source="root" defaultValue={true} />
      </SimpleForm>
    </Edit>
  )
};
export const LUCreate = props => {
  const { store_id: storeIdString, store_name: storeName } = parse(props.location.search);
  console.log(storeIdString, storeName)
  const storeId = storeIdString ? parseInt(storeIdString, 10) : "";
  const redirect = storeId ? `/stores/${storeId}/3` : false;

  const Title = (props) => {
    return <span>Create local user {props.storeName ? `to ${props.storeName}` : ""}</span>;
  };

  return (
    <Create {...props} title={<Title storeName={storeName}/>}>
      <SimpleForm redirect={redirect}>
        <DisabledInput source="store_id" defaultValue={storeIdString} />
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="password" />
        <BooleanInput source="active" defaultValue={true} />
        <BooleanInput source="root" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};
