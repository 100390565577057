import config from "../config";
import React from "react";
import { addField } from "ra-core";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import MyPolygon from "./MyPolygon";
const center = { lat: 47.920445, lng: 106.917039 };
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${config.apikeys.GOOGLE_MAP}&v=3.exp&libraries=geometry,drawing,places`;

const MapField = compose(
  withProps({
    googleMapURL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `700px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { record = {} } = props;

  const onPolygonComplete = polygon => {
    props.handleChange(
      polygon
        .getPaths()
        .getArray()[0]
        .getArray()
    );
  };

  return (
    <GoogleMap defaultZoom={13} defaultCenter={center}>
      <DrawingManager
        defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
          }
        }}
        onPolygonComplete={onPolygonComplete}
      />
      {record.id && <MyPolygon {...props} />}
    </GoogleMap>
  );
});

class MapInputComponent extends React.Component {
  render() {
    return (
      <MapField
        {...this.props}
        handleChange={results => {
          this.props.input.onChange(results);
          this.forceUpdate();
        }}
      ></MapField>
    );
  }
}

export default addField(MapInputComponent);
