import React from 'react';

export const ErrorField = ({ record = {} }) => {
  const {
    parse_error_msg: errorMsg,
    deleted,
    duplicated,
    editme
  } = record
  if (errorMsg && errorMsg.length) {
    return (<font color="red">{errorMsg.toLowerCase()}</font>)
  } else if (deleted) {
    return (<font color="blue">{"deleted"}</font>)
  } else if (duplicated) {
    return  (<font color="blue">{"duplicated"}</font>)
  } else if (editme) {
    return (<font color="blue">{"editme"}</font>)
  }
  return (<font color="green">{"OK"}</font>);
};