import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';

import roleOption from './roleOption'

export const AdminCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <SelectInput source="role" choices={roleOption} />
          <TextInput source="username" />
          <TextInput source="password" />
      </SimpleForm>
  </Create>
);