import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showNotification, withDataProvider, UPDATE } from 'react-admin'
import Button from '@material-ui/core/Button';

class HighlightButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = () => {
      const { dataProvider, dispatch, record } = this.props;
      dataProvider(UPDATE, 'zars', {id: record.id, data: {
        type: 'highlight'
      }}).then(() => {
        dispatch(showNotification('Zar highlighted'))
      }).catch(e => {
        console.log(e)
        dispatch(showNotification('Error: Zar can\'t highlighted', 'warning'))
      });
    }
  }
  

  render() {
      return (<Button variant="contained" color="primary" onClick={this.handleClick}>Highligh</Button>);
  }
}

HighlightButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default withDataProvider(HighlightButton)