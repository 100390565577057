import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

export default function auth (type, params) {
  
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request('https://admin.ubinfo.mn/v1/api/admins/login', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json' }),
      body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ token }) => {
        localStorage.setItem('token', token)
      })
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status  = params.status;
    if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }
  return Promise.resolve();
}
