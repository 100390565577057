import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin'
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import Link from '../components/Link'
import { ErrorField } from '../components/ErrorField'
import { DateAgoField } from '../components/DateAgoField'

const Analytics = (props) => (
  <LineChart width={1500} height={400} data={Object.values(props.data)}>
    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
    <CartesianGrid stroke="#ccc" />
    <XAxis dataKey="name" />
    <YAxis />
  </LineChart>
)

export const AnalyticsList = (props) => (
  <List title="Analytics: Мэдээ нэмэгдэлт" {...props} perPage={20}>
    <Analytics />
  </List>
)

export const CheckList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <ReferenceField source="id" reference="publishers" sortable={false} label="publisher">
        <TextField source="name" />
      </ReferenceField>
      <DateAgoField source="createdAt" label="Last Date" sortable={false}/>
      <ErrorField source="parse_error_msg" label="Status" sortable={false} />
      <Link source="link" sortable={false} />
    </Datagrid>
  </List>
);