import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  DateField
} from "react-admin";
import ZonePreview from "../../components/ZonePreview";

const AdminFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const AdminList = props => (
  <List filters={<AdminFilter />} {...props}>
    <Datagrid rowClick="edit">
      <ZonePreview source="image" />
      <TextField source="name" />
      <BooleanField source="active" />
      <BooleanField source="hasEmail" />
      <BooleanField source="hasTel" />
      <DateField source="created" showTime />
    </Datagrid>
  </List>
);
