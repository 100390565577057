import React from "react";
import { Route } from "react-router-dom";

import StoreOrder from "./modules/stores/order";

export default [
  <Route
    exact
    path="/stores/order"
    key="/stores/order"
    component={StoreOrder}
  />
];
