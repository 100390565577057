import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  LongTextInput,
  ImageInput,
  ImageField
} from "react-admin";

import schema from "./schema.json";

function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

export const AdminCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="summary">
        <TextInput source="name" defaultValue={"Yeye coffee"} />
        <LongTextInput
          source="desc"
          rows={2}
          defaultValue={"Yeye бүтээгдэхүүний судалгаа, Хонжвортой"}
        />
        <BooleanInput source="active" defaultValue={true} />
        <BooleanInput source="hasEmail" defaultValue={false} />
        <BooleanInput
          source="hasTel"
          label="Has telephone"
          defaultValue={false}
        />
        <LongTextInput
          source="schema"
          rows={10}
          defaultValue={JSON.stringify(schema, null, 2)}
        />
      </FormTab>
      <FormTab label="image">
        <ImageInput
          format={formatLogo}
          source="image"
          label="Image"
          accept="image/*"
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
