import React from "react";
import { addField } from "ra-core";
import { compose, withStateHandlers } from "recompose";
import MaterialChipInput from "material-ui-chip-input";

const ChipInput = compose(
  withStateHandlers(
    ({ record, source, label, handleChange }) => {
      const values = record[source];
      const wrappedHandleChange = (value, index) => {
        if (index === undefined) {
          values.push(value);
        } else {
          values.splice(index, 1);
        }
        handleChange(values);
      };
      return {
        handleChange: wrappedHandleChange,
        values: values,
        label
      };
    },
    {
      onAdd: props => chip => {
        props.handleChange(chip);
      },
      onDelete: props => (chip, index) => {
        props.handleChange(chip, index);
      }
    }
  )
)(props => {
  return (
    <div style={{ paddingTop: "30px" }}>
      <MaterialChipInput
        value={props.values}
        onAdd={props.onAdd}
        onDelete={props.onDelete}
        label={props.label}
        variant="outlined"
      />
    </div>
  );
});

class ChipInputComponent extends React.Component {
  render() {
    let label = this.props.source.split("_").join(" ");
    label = label.charAt(0).toUpperCase() + label.slice(1);
    this.props.record[this.props.source] =
      this.props.record[this.props.source] || [];
    return (
      <ChipInput
        record={this.props.record}
        source={this.props.source}
        handleChange={values => {
          this.props.input.onChange(values);
          this.forceUpdate();
        }}
        label={label}
      />
    );
  }
}

export default addField(ChipInputComponent);
