import React from 'react';
import { Filter, List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput, DisabledInput, DateField } from 'react-admin';
import MapPolygonField from '../components/MapPolygonField'
import ZonePreview from '../components/ZonePreview'

const ZoneFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export const ZoneList = (props) => (
  <List {...props} filters={<ZoneFilter />} sort={{ field:'id', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <ZonePreview source="preview" label="Preview" />
      <TextField source="id" />
      <TextField source="name" label="Нэр"/>
      <DateField source="updatedAt" label="Шинэчлэгдсэн огноо" />
      <DateField source="createdAt" label="Нэмэгдсэн огноо" />
    </Datagrid>
  </List>
);

const ZoneTitle = ({record}) => {
  return <span>Zone {record ? `${record.name}` : ''}</span>
}

export const ZoneEdit = props => (
  <Edit {...props} title={<ZoneTitle/>}>
      <SimpleForm>
          <DisabledInput source="id" />
          <TextInput source="name" />
          <MapPolygonField source="polygon" />
      </SimpleForm>
  </Edit>
);

export const ZoneCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" />
          <MapPolygonField source="polygon" />
      </SimpleForm>
  </Create>
);