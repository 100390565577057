import React from "react";
import {
  List,
  Responsive,
  Datagrid,
  TextField,
  ReferenceField,
  SimpleList,
  NumberField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

import Link from "../../components/Link";
import { ErrorField } from "../../components/ErrorField";
import ZonePreview from "../../components/ZonePreview";

const ItemFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Publisher"
      source="publisher_id"
      reference="publishers"
      filter={{ active: true }}
      perPage={1000}
      sort={{ field: "name", order: "ASC" }}
      allowEmpty
      alwaysOn
    >
      {/* <SelectInput optionText="name" /> */}
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default function list(props) {
  return (
    <List
      {...props}
      filters={<ItemFilter />}
      sort={{ field: "item_id", order: "DESC" }}
    >
      <Responsive
        small={
          <SimpleList
            linkType="show"
            primaryText={(record) => record.title}
            secondaryText={(record) => `${record.read_num} views`}
            tertiaryText={(record) =>
              new Date(record.createdAt).toLocaleString()
            }
          />
        }
        medium={
          <Datagrid rowClick="show">
            <ZonePreview source="thumb" />
            <ReferenceField source="publisher_id" reference="publishers">
              <TextField source="name" />
            </ReferenceField>
            <ErrorField source="parse_error_msg" label="Status" />
            <TextField source="title" />
            <Link source="link" />
            <NumberField source="read_num" />
            <DateField source="createdAt" showTime />
          </Datagrid>
        }
      />
    </List>
  );
}
