import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  DisabledInput,
  LongTextInput,
  BooleanInput,
  ImageInput,
  ImageField
} from "react-admin";

const AdminTitle = ({ record }) => {
  return <span>Schema {record ? `${record.name}` : ""}</span>;
};
const Sheet = ({ record, source }) => {
  if (!record[source] || Array.isArray(record[source])) return <br />;
  return (
    <ul>
      {record[source].split("\n").map((row, index) => {
        return <li key={`sheet-li-${index}`}>{row}</li>;
      })}
    </ul>
  );
};
function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}
export const AdminEdit = props => (
  <Edit title={<AdminTitle />} {...props}>
    <TabbedForm>
      <FormTab label="summary">
        <DisabledInput source="id" />
        <TextInput source="name" />
        <LongTextInput source="desc" rows={2} />
        <BooleanInput source="active" />
        <BooleanInput source="hasEmail" />
        <BooleanInput source="hasTel" label="Has telephone" />
        <LongTextInput source="schema" rows={10} />
        <Sheet source="sheet" />
      </FormTab>
      <FormTab label="image">
        <ImageInput
          format={formatLogo}
          source="image"
          label="Image"
          accept="image/*"
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
