import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  SingleFieldList,
  DateField
  // ChipField
} from "react-admin";

import CardActions from "@material-ui/core/CardActions";

import ChipField from "../../components/ChipField";

import ZonePreview from "../../components/ZonePreview";
import HighlightButton from "./HighlightButton";
import SpecialButton from "./SpecialButton";
import RemoveButton from "./RemoveButton";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const ZarActions = ({ data }) => (
  <CardActions style={cardActionStyle}>
    <SpecialButton record={data} />
    <HighlightButton record={data} />
    <RemoveButton record={data} />
  </CardActions>
);

export default function ZarShow(props) {
  return (
    <Show actions={<ZarActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="title" />
        <ArrayField source="tags">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ZonePreview source="image" />
        <TextField source="desc" />
        <DateField source="createdAt" showTime />
      </SimpleShowLayout>
    </Show>
  );
}
