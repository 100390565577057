import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const styles = {
  link: {
    textDecoration: 'none'
  },
  icon: {
    width: '0.5em',
    paddingLeft: 2
  }
}

const ItemLink = ({ record = {}, source, classes }) => {
  const text = (record[source] || '').substring(0, 30)
  return (
    <a href={record[source]} className={classes.link} target="_blank" without rel="noopener noreferrer">
        {text}
        <LaunchIcon className={classes.icon} />
    </a>
  )
}
export default withStyles(styles)(ItemLink)