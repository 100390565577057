import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  TextInput
} from "react-admin";
import ZonePreview from "../../components/ZonePreview";
import ChipField from "../../components/ChipField";

const AdminFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search"
      source="q"
      alwaysOn
      placeholder="title, phones, id or Z4466 code"
      style={{ width: "300px" }}
    />
  </Filter>
);

export const AdminList = props => (
  <List
    filters={<AdminFilter />}
    sort={{ field: "id", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ZonePreview source="image" />
      <TextField source="title" />
      <ArrayField source="tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
