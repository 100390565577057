import React from 'react';
import { 
  Filter, List, Datagrid, Edit, Create, TabbedForm, FormTab,
  TextField, BooleanField, DateField, ImageField,
  TextInput , BooleanInput, SelectInput, LongTextInput, DateTimeInput, ImageInput,
} from 'react-admin';
import ZonePreview from '../components/ZonePreview';
import { MapInput } from '../components/MapField';

const typeOption = ['uzesgelen','toglolt','udeshleg','tsengeen','hoshin_shog','uraldaan','temtseen','uzver','jagsaal','tsuglaan','uulzalt','iltgel_lect','odorlog','hural','bayar','aylal','busad']
  .map(i => {
    return { id: i, name: i}
  });

const ShowFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput label="Type" source="type" choices={typeOption} alwaysOn />
  </Filter>
)

export const ShowList = (props) => (
  <List {...props} filters={<ShowFilter />} sort={{
    field: 'start_time',
    order: 'DESC'
  }} export={false}>
    <Datagrid rowClick="edit">
      <ZonePreview source="image" />
      <TextField source="type" />
      <TextField source="name" />
      <BooleanField source="active" />
      <DateField source="start_time" showTime/>
    </Datagrid>
  </List>
);

const ShowTitle = ({record}) => {
  return <span>Show {record ? `${record.name}` : ''}</span>
}
function formatLogo(value) {
  if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it 
    return { url: value };
  } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}
export const ShowEdit = props => {
  return (
    <Edit title={<ShowTitle/>} {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <SelectInput source="type" choices={typeOption} />
          <TextInput source="name" />
          <TextInput source="organizer" />
          <TextInput source="venue" />
          <DateTimeInput source="start_time" defaultValue={new Date()} />
          <DateTimeInput source="end_time" defaultValue={new Date()} />
          <BooleanInput source="active" defaultValue={true} />
          <LongTextInput source="desc" />
          <LongTextInput source="address" />
        </FormTab>
        <FormTab label="logo">
          <ImageInput format={ formatLogo } source="image" label="Image" accept="image/*">
            <ImageField source="url" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label="location">
          <MapInput source="location" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};
export const ShowCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="summary">
        <SelectInput source="type" choices={typeOption} />
        <TextInput source="name" />
        <TextInput source="organizer" />
        <TextInput source="venue" />
        <DateTimeInput source="start_time" defaultValue={new Date()} />
        <DateTimeInput source="end_time" defaultValue={new Date()} />
        <BooleanInput source="active" defaultValue={true} />
        <LongTextInput source="desc" />
        <LongTextInput source="address" />
      </FormTab>
      <FormTab label="logo">
        <ImageInput format={ formatLogo } source="image" label="Image" accept="image/*">
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="location">
          <MapInput source="location" />
        </FormTab>
    </TabbedForm>
  </Create>
);