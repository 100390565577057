import icon from '@material-ui/icons/Store'
import { StoreList as list } from './list'
import { StoreForm as edit } from './form'
import { StoreForm as create } from './form'

export default {
  icon,
  list,
  edit,
  create
}