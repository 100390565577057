import config from '../config'
import React from 'react'
import { addField } from 'ra-core';
import { compose, withProps, withStateHandlers } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
const center = {lat:47.920445, lng:106.917039}
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${config.apikeys.GOOGLE_MAP}&v=3.exp&libraries=places`

const MapField = compose(
  withStateHandlers(({ record, handleChange }) => {
    const {lat, lon} = record
    if (lat && lon) {
      return {
        handleChange,
        isMarkerShown: true,
        markerPosition: {lat, lng: lon}
      }
    }
    return {
      handleChange,
      isMarkerShown: false,
      markerPosition: null
    }
  }, {
    onMapClick: (props) => (e) => {
      props.handleChange(e.latLng)
      return {
        handleChange: props.handleChange,
        markerPosition: e.latLng,
        isMarkerShown: true
      }
    }
  }),
  withProps({
    googleMapURL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultZoom={13} 
      defaultCenter={center}
      onClick={props.onMapClick}
    >
    {props.isMarkerShown && <Marker position={props.markerPosition} />}
    </GoogleMap>
  )
});

class MapInputComponent extends React.Component {
  render() {
    return <MapField {...this.props} handleChange={(latLng) => {
      this.props.input.onChange(latLng.lat() + ','+latLng.lng())
      this.forceUpdate()
    }} />
  }
}


export const MapInput = addField(MapInputComponent)