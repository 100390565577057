import React from "react";
import Chip from "@material-ui/core/Chip";

const color = {
  category: "default",
  phone: "primary",
  highlight: "secondary"
};

const ChipField = ({ record, source }) => {
  return <Chip label={record[source]} color={color[record.type]} />;
};
export default ChipField;
