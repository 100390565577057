import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  showNotification,
  withDataProvider,
  DELETE,
  Confirm
} from "react-admin";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import Button from "@material-ui/core/Button";

class RemoveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.handleClick = () => {
      this.setState({ isOpen: true });
    };
    this.handleDialogClose = () => {
      this.setState({ isOpen: false });
    };

    this.handleConfirm = () => {
      const { dataProvider, dispatch, record, goBack } = this.props;
      dataProvider(DELETE, "zars", {
        id: record.id
      })
        .then(() => {
          dispatch(showNotification("Zar removed"));
          this.setState({ isOpen: false });
          goBack();
        })
        .catch(e => {
          console.log(e);
          dispatch(showNotification("Error: Zar can't removed", "warning"));
          this.setState({ isOpen: false });
          goBack();
        });
    };
  }

  render() {
    console.log(this.props.record);
    return (
      <Fragment>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleClick}
          disabled={
            this.props.record && this.props.record.user_id ? true : false
          }
        >
          Remove
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Update View Count"
          content="Are you sure you want to reset the views for these items?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}

RemoveButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.object
};

export default connect(null, { goBack })(withDataProvider(RemoveButton));
