import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';

import CardIcon from './CardIcon';
const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52
  }
};

const MonthlyRevenue = ({ Icon, Image, value, classes, label, bgColor }) => (
  <div className={classes.main}>
    {Image && <CardIcon Image={Image} bgColor={bgColor} />}
    {Icon && <CardIcon Icon={Icon} bgColor={bgColor} />}
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {label}
      </Typography>
      <Typography variant="headline" component="h2">
        <EyeIcon />
        {value}
      </Typography>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(MonthlyRevenue);
