import React from "react";
import { Link } from "react-router-dom";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em"
  }
};

export const AddLocalUserButton = withStyles(styles)(({ classes, record }) => {
  console.log('>>>>', record)
  return (
    <Button
      className={classes.button}
      variant="raised"
      component={Link}
      to={`/localusers/create?store_id=${record.id}&store_name=${record.name}`}
      label="Add user"
      title="Add user"
    >
      <GroupAddIcon />
    </Button>
  )
});
export const UpdateLocalUserButton = withStyles(styles)(({ record }) => {
  return (
    <Button
      component={Link}
      to={`/localusers/${record.id}?store_id=${record.store_id}&store_name=${record.name}`}
      label="edit"
      title="edit"
    >
      <EditIcon />
    </Button>
  )
});
