import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, DisabledInput } from 'react-admin';

import roleOption from './roleOption'

const AdminTitle = ({record}) => {
  return <span>Admin {record ? `${record.username}` : ''}</span>
}

export const AdminEdit = props => (
  <Edit title={<AdminTitle/>} {...props}>
      <SimpleForm>
          <DisabledInput source="id" />
          <SelectInput source="role" choices={roleOption} />
          <TextInput source="username" />
          <TextInput source="password" />
      </SimpleForm>
  </Edit>
);