import React from 'react';
import moment from 'moment'

export const DateAgoField = ({ record = {} }) => {
  const { createdAt } = record;
  const now = moment();
  let ago = ''
  const days_ago = now.diff(moment(createdAt), 'days')
  const hours_ago = now.diff(moment(createdAt), 'hours')
  const minutes_ago = now.diff(moment(createdAt), 'minutes')
  if (days_ago !== 0) {
    ago = days_ago + ' days ago'
  } else if (hours_ago !== 0) {
    ago = hours_ago + ' hours ago'
  } else {
    ago = minutes_ago + ' minutes ago'
  }
  return (<p>{ago}</p>)
};