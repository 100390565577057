import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showNotification, withDataProvider, UPDATE } from 'react-admin'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class SpecialButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: 0
    }
    this.handleHourChange = (e) => {
      this.setState({
        hour: e.target.value
      });
    }
    this.handleSpecialClick = () => {
      const { dataProvider, dispatch, record } = this.props;
      if (!this.state.hour) {
        dispatch(showNotification('Please specify hour', 'warning'))
        return;
      }
      dataProvider(UPDATE, 'zars', {id: record.id, data: {
        type: 'special',
        hour: this.state.hour
      }}).then(() => {
        dispatch(showNotification('Zar settled special'))
      }).catch(e => {
        console.log(e)
        dispatch(showNotification('Error: Zar can\'t settle special', 'warning'))
      });
    }

    this.handleUnspecialClick = () => {
      const { dataProvider, dispatch, record } = this.props;
      dataProvider(UPDATE, 'zars', {id: record.id, data: {
        type: 'unspecial'
      }}).then(() => {
        dispatch(showNotification('Zar unsettled special'))
      }).catch(e => {
        console.log(e)
        dispatch(showNotification('Error: Zar can\'t unsettle special', 'warning'))
      });
    }
  }
  
  
  render() {
    const specialButton = (
      <span>
        <TextField 
          style={{marginTop: -10, marginRight: 5}}
          id="standard-basic"
          label="Hour"
          value={this.state.value}
          onChange={this.handleHourChange}
        />
        <Button variant="contained" color="secondary" onClick={this.handleSpecialClick}>Special</Button>
      </span>
    )
    const unspecialButton = <Button variant="contained" color="secondary" onClick={this.handleUnspecialClick}>Unspecial</Button>
    if (!this.props.record) return specialButton;
    const { record } = this.props
    const btn = record.special ? unspecialButton : specialButton;
    return btn;
  }
}

SpecialButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default withDataProvider(SpecialButton)