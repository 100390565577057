import React from "react";
import {
  List,
  Datagrid,
  Filter,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  TextInput
} from "react-admin";

import ImageField from "./ImageField";
import ChipField from "../../components/ChipField";

const StoreFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const StoreList = props => (
  <List
    {...props}
    filters={<StoreFilter />}
    sort={{
      field: "id",
      order: "ASC"
    }}
  >
    <Datagrid rowClick="edit">
      <ImageField source="background" label="image" />
      <TextField source="name" />
      <TextField source="career" />
      <TextField source="user.email" label="user email" />
      <ArrayField source="show_keywords">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="user.createdAt" label="createdAt" />
    </Datagrid>
  </List>
);
