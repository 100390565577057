import React from 'react'
import { addField } from 'ra-core';
import { Polygon } from 'react-google-maps'

class MyPolygon extends React.Component {
  constructor(props) {
    super(props)
    const { id, polygon } = this.props.record
    this.state = {
      id,
      paths: polygon
    }
  }

  render() {
    console.log(this.state.paths)
    return (
      <Polygon paths={this.state.paths} onClick={()=> {
        this.setState({
          ...this.state,
          paths: []
        })
        this.props.input.onChange([])
      }} />
    )
  }

}
export default addField(MyPolygon)