import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  DateField,

  Filter,
  TextInput,
  BooleanInput,
} from "react-admin";

import ZonePreview from "../../components/ZonePreview";

const StoreFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <BooleanInput label="Active" source="active" alwaysOn/>
  </Filter>
);

export const StoreList = props => (
  <List
    {...props}
    filters={<StoreFilter />}
    sort={{
      field: "`SpecialStore`.`order`",
      order: "ASC"
    }}
    filterDefaultValues={{ active: true }}
  >
    <Datagrid rowClick="edit">
      <ZonePreview source="logo" />
      <TextField source="name" />
      <NumberField source="special.order" label="Order" />
      <BooleanField source="special.active" label="Active" />
      <BooleanField source="special.has_category" label="Has category" />
      <ZonePreview source="zone_image" />
      <NumberField source="special.product_count" label="Product count" />
      <TextField source="special.start_time" label="Start time" />
      <TextField source="special.end_time" label="End time" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);