import React, { useState, Fragment } from "react";
import {
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  RichTextField,
  withDataProvider,
  showNotification,
  CREATE,
  Confirm,
} from "react-admin";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right",
};

const PushButtonComponent = (props) => {
  const [sent, setSent] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { dataProvider, dispatch, record } = props;
  const handleClick = () => {
    setOpen(true);
  };
  const handleConfirm = () => {
    setSent(true);
    setOpen(false);
    dataProvider(CREATE, `items/${record.id}/push`, { data: {} })
      .then(() => {
        dispatch(showNotification("Push notification sent"));
      })
      .catch((e) => {
        dispatch(
          showNotification("Error: couldn't send push notification", "warning")
        );
      });
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Button disabled={sent} color="secondary" onClick={handleClick}>
        PUSH
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Send Push Notification"
        content="Are you sure you want to push this news?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

PushButtonComponent.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

const PushButton = withDataProvider(PushButtonComponent);

const Actions = ({ data }) => {
  return (
    <CardActions style={cardActionStyle}>
      <PushButton record={data} />
    </CardActions>
  );
};
export default function show(props) {
  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source="title" />
        <RichTextField
          label="Content"
          source="body.content"
          style={{ width: "400px" }}
        />
        <DateField source="createdAt" showTime />
      </SimpleShowLayout>
    </Show>
  );
}
