import React from "react";
import {
  Create,
  Edit,
  TabbedForm,
  FormTab,
  ImageField,
  TextInput,
  ImageInput
} from "react-admin";
import ChipInput from "../../components/ChipInput";

const StoreTitle = ({ record }) => {
  return (
    <div className="storeTitle">
      <span>{record && record.id ? `${record.name}` : "Create Store"}</span>
      <img src={record.background} height={48} alt={record.name} />
    </div>
  );
};

function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

export const StoreForm = props => {
  const MainForm = props.id ? Edit : Create;
  return (
    <MainForm {...props} title={<StoreTitle />}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" label="Name" />
          <TextInput source="career" label="Career" />
          <TextInput source="user.email" label="Email" />
          <TextInput source="user.password" label="Password" />
        </FormTab>
        <FormTab label="image">
          <ImageInput
            format={formatLogo}
            source="background"
            label="Image"
            accept="image/*"
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label="keywords">
          <ChipInput source="keywords" />
          <ChipInput source="tweet_keywords" />
          <ChipInput source="stat_keywords" />
        </FormTab>
      </TabbedForm>
    </MainForm>
  );
};
