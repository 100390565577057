import React from 'react';
import { Filter, List, Datagrid, TextField, TextInput, SelectField } from 'react-admin';
import { PasswordField } from '../../components/PasswordField';

import roleOption from './roleOption';

const AdminFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export const AdminList = (props) => (
  <List filters={<AdminFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="username" />
      <PasswordField source="password" />
      <SelectField source="role" choices={roleOption}/>
    </Datagrid>
  </List>
);