import icon from "@material-ui/icons/BarChart";
import { AdminList as list } from "./list";
import { AdminEdit as edit } from "./edit";
import { AdminCreate as create } from "./create";

export default {
  icon,
  list,
  edit,
  create
};
