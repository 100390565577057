import React, { Component } from "react";
import { GET_LIST, withDataProvider } from "react-admin";
import compose from "recompose/compose";
import { connect } from "react-redux";
import HourIcon from "@material-ui/icons/WatchLaterRounded";
import NowIcon from "@material-ui/icons/Replay";

import Stat from "../dashboard/Stat";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" }
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchData();
    this.intervalID = setInterval(() => {
      this.fetchData();
    }, 1000 * 60 * 5);
  }

  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  fetchData() {
    this.fetchOrders();
  }

  async fetchOrders() {
    const { dataProvider } = this.props;
    const aMonthAgo = new Date();
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    const { data: configs } = await dataProvider(GET_LIST, "configs", {
      filter: {
        key: [
          "READ_NOW",
          "READ_30_MINUTES",
          "READ_1_HOURS",
          "READ_3_HOURS",
          "READ_6_HOURS"
        ]
      },
      sort: { field: "date", order: "DESC" },
      pagination: { page: 1, perPage: 50 }
    });
    const aggregations = configs.reduce((configs, config) => {
      return {
        ...configs,
        [config.id.toLowerCase()]: config.value
      };
    }, {});
    this.setState(aggregations);
  }

  render() {
    console.log("state", this.state);
    const {
      read_now = 0,
      read_30_minutes = 0,
      read_1_hours = 0,
      read_3_hours = 0,
      read_6_hours = 0
    } = this.state;
    return (
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <Stat
              Icon={NowIcon}
              value={read_now}
              label="яг одоо"
              bgColor="#4caf50"
            />
            <Stat
              Icon={HourIcon}
              value={read_30_minutes}
              label="сүүлийн 30 мин"
              bgColor="#ff9800"
            />
            <Stat
              Icon={HourIcon}
              value={read_1_hours}
              label="сүүлийн 1 цаг"
              bgColor="#31708f"
            />
            <Stat
              Icon={HourIcon}
              value={read_3_hours}
              label="сүүлийн 3 цаг"
              bgColor="#31708f"
            />
            <Stat
              Icon={HourIcon}
              value={read_6_hours}
              label="сүүлийн 6 цаг"
              bgColor="#31708f"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion
});

export default compose(connect(mapStateToProps), withDataProvider)(Dashboard);
