import React from "react";
import { Admin, Resource } from "react-admin";

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";

import { Dashboard } from "./modules/dashboard/index";
// import Dashboard from "./modules/Dashboard";
import admins from "./modules/admins";
import stores from "./modules/stores";
import zars from "./modules/zars";
import personal from "./modules/personal";
import surveys from "./modules/surveys";
import items from "./modules/items";
import { ShowList, ShowEdit, ShowCreate } from "./modules/shows";
import { PublisherList } from "./modules/publishers";
import { AnalyticsList, CheckList } from "./modules/analytics";
import { ZoneList, ZoneEdit, ZoneCreate } from "./modules/zones";
import { LUEdit, LUCreate } from "./modules/localusers";
import customRoutes from "./customRoutes";

import UserIcon from "@material-ui/icons/Group";
import PublisherIcon from "@material-ui/icons/RecordVoiceOver";
import AnalyticsIcon from "@material-ui/icons/InsertChart";
import CheckIcon from "@material-ui/icons/CheckBox";
import ZoneIcon from "@material-ui/icons/Explore";
import ShowIcon from "@material-ui/icons/Event";

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={Dashboard}
    customRoutes={customRoutes}
  >
    <Resource
      name="analytics/items"
      icon={AnalyticsIcon}
      list={AnalyticsList}
    />
    <Resource name="analytics/checks" icon={CheckIcon} list={CheckList} />
    <Resource name="items" {...items} />
    <Resource name="publishers" icon={PublisherIcon} list={PublisherList} />
    <Resource name="admins" {...admins} />
    <Resource
      name="shows"
      icon={ShowIcon}
      list={ShowList}
      edit={ShowEdit}
      create={ShowCreate}
    />
    <Resource name="zars" {...zars} />
    <Resource name="personals" {...personal} />
    <Resource
      name="zones"
      icon={ZoneIcon}
      list={ZoneList}
      edit={ZoneEdit}
      create={ZoneCreate}
    />
    <Resource name="stores" {...stores} />
    <Resource
      name="localusers"
      icon={UserIcon}
      edit={LUEdit}
      create={LUCreate}
    />
    <Resource name="scategories" />
    <Resource name="surveys" {...surveys} />
  </Admin>
);
export default App;
